body {
  background-color: #FFFFFF;
  color: #000000;
  letter-spacing: 0.05em;
}

.react-date-picker__wrapper {
  border: none;
  width: 100%;
}

.react-date-picker__clear-button  {
  margin-left: auto;
}

.react-confirm-alert, .react-confirm-alert-body {
  max-width: 100%;
}